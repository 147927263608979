const project1 = {
  img: require('./img/recipe-api.png'),
  imgLazy: require('./img/recipe-api.png'),
  title: 'Blog Recipe Scraper API',
  description: '',
  goal: '',
  tech: [
    'Python',
    'Django',
    'Django-Rest-Framework',
    'RestAPI',
    'Web Scraping',
    'Docker',
    'AWS',
  ],
  demo: 'https://junkfreerecipes.netlify.app/v1/api/docs/',
  gitHub: 'https://github.com/mseidel819/recipe-app-api',
};

const project2 = {
  img: require('./img/junkfreerecipes.png'),
  imgLazy: require('./img/junkfreerecipes.png'),
  title: 'Junk Free Recipes',
  description: '',
  goal: '',
  tech: ['TypeScript', 'NextJs', 'React Query', 'next-auth', 'JSON web token'],
  demo: 'https://junkfreerecipes.netlify.app/',
  gitHub: 'https://github.com/mseidel819/recipe-library',
};

const project3 = {
  img: require('./img/planet-sh.webp'),
  imgLazy: require('./img/planet-sh-lazy.webp'),
  title: 'Planet Facts',
  description: '',
  goal: '',
  tech: ['TypeScript', 'NextJs', 'React'],
  demo: 'https://splendid-pegasus-ce4c5e.netlify.app/',
  gitHub: 'https://github.com/mseidel819/planet-facts',
};

const project4 = {
  img: require('./img/githubScreenshot.webp'),
  imgLazy: require('./img/githubScreenshot-lazy.webp'),
  title: 'GitHub Search App',
  description:
    "This is a solution to the GitHub user search app challenge on Frontend Mentor. Users should be able to view the optimal layout for the app depending on their device's screen size, see hover states for all interactive elements on the page, search for GitHub users by their username, see relevant user information based on their search, and switch between light and dark themes",
  goal: 'To create a functional web app that accesses the GitHub API from strict design parameters using React and Material UI.',
  tech: ['JavaScript', 'React', 'Material UI', 'RestAPI'],
  demo: 'https://chimerical-frangipane-1137e5.netlify.app/',
  gitHub: 'https://github.com/mseidel819/github-user-search-app',
};

const project5 = {
  img: require('./img/t2l.png'),
  imgLazy: require('./img/t2l-lazy.webp'),
  title: 'Tech2Live: Empower through technology',
  description:
    'Tech2Live is an early stage volunteer startup in the Tech-for-Good space. Our goal is to create a platform to financially aid survivors of domestic and sexual violence through the support of donors.',
  tech: ['JavaScript', 'HTML', 'Sass'],
  demo: 'https://tech2live.org/',
};

const project6 = {
  img: require('./img/comments-redux.webp'),
  imgLazy: require('./img/comments-redux-lazy.webp'),
  title: 'Comments Section w/ Redux',
  description:
    "This is a solution to the interactive comments section challenge on Frontend Mentor. Figma files were provided. Users should be able to view the optimal layout for the app depending on their device's screen size, see hover states for all interactive elements on the page, Create, Read, Update, and Delete comments and replies, Upvote and downvote comments, and use localStorage to save the current state in the browser that persists when the browser is refreshed.",
  goal: 'To create a functional web app from strict design parameters using React, Material UI, and Redux.',
  tech: ['TypeScript', 'Material Ui', 'React', 'Redux'],
  demo: 'https://tranquil-capybara-a9a135.netlify.app/',
  gitHub: 'https://github.com/mseidel819/comments-section-redux',
};

// const project5 = {
//   img: require('./img/job-listing-ss.webp'),
//   imgLazy: require('./img/job-listing-ss-lazy.webp'),
//   title: 'Static Job Listings',
//   description:
//     "This is a solution to the Job listings with filtering challenge on Frontend Mentor. Users should be able to view the optimal layout for the site depending on their device's screen size, see hover states for all interactive elements on the page, and filter job listings based on the categories",
//   goal: 'To create a functional web app from strict design parameters using React and Material UI.',
//   tech: ['TypeScript', 'React', 'Material UI'],
//   demo: 'https://astonishing-bavarois-d35327.netlify.app/',
//   gitHub: 'https://github.com/mseidel819/static-job-listings',
// };

// const project6 = {
//   img: require('./img/pet_adopter.webp'),
//   imgLazy: require('./img/pet_adopter-lazy.webp'),
//   title: 'Dog Finder',
//   description:
//     'The Dog-Finder app accesses the Petfinder API in order to search for adoptable dogs within 10 miles of an entered location. After selecting a dog, its info/details, as well as the organization caring for it, are rendered dynamically. This project also uses MVC architecture.',
//   goal: "The goal of this project was to create a page from scratch based off of the skills learned from completing both 'The Complete JavaScript Course 2022: From Zero to Expert!', and 'Build Responsive Real-World Websites with HTML and CSS' on Udemy.com.",
//   tech: ['JavaScript', 'HTML', 'CSS', 'RESTApi'],
//   demo: 'https://startling-mochi-d0adad.netlify.app/',
//   gitHub: 'https://github.com/mseidel819/pet_adopter',
// };

export const projectData = [
  project1,
  project2,
  project3,
  project4,
  project5,
  project6,
];

export const skillsArr = [
  'Python',
  'Django',
  'Web Scraping',
  'JavaScript',
  'TypeScript',
  'React',
  'Next.js',
  'Svelte',
  'Material-UI',
  'Node.js',
  'MongoDB',
  'PostgreSQL',
  'Docker',
  'AWS',
];
export const softSkillsArr = [
  'Problem Solving',
  'Continuing Education',
  'Teamwork',
  'Empathy',
  'Patience',
  'Communication',
];
